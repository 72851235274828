import React, { useEffect, useRef, useState } from "react";
import "../../styles/components/SearchableDropdown/style.scss";

const SearchableDropdown = ({
  options,
  notIncludeAll,
  label,
  id,
  value,
  placeholder,
  handleChange,
  className,
  style,
}) => {
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const allOption = {
    "name": "ALL",
  };

  const inputRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", toggle);
    return () => document.removeEventListener("click", toggle);
  }, []);

  const selectOption = (option) => {
    setQuery(() => "");
    handleChange(option.name);
    setIsOpen((isOpen) => !isOpen);
  };

  function toggle(e) {
    setIsOpen(e && e.target === inputRef.current);
  }

  const filter = (options) => {
    return options.filter(
      (option) => option.name?.toLowerCase().indexOf(query.toLowerCase()) > -1
    );
  };

  return (
    <div className={`${className} dropdown`} style={style ?? {}}>
      <div className="control">
        <div className="selected-value">
          <label>{label}</label>
          <input
            type="text"
            ref={inputRef}
            value={value}
            name="searchTerm"
            placeholder={placeholder}
            onChange={(e) => {
              setQuery(e.target.value);
              handleChange(e.target.value);
            }}
            onClick={toggle}
            className={`${isOpen ? "open" : ""}`}
          />
        </div>
        <div className={`arrow ${isOpen ? "open" : ""}`} onClick={toggle}></div>
      </div>

      <div className={`options ${isOpen ? "open" : ""}`}>

        {!notIncludeAll && (
        <div
          onClick={() => selectOption(allOption)}
          className="option"
        >
          {allOption.name}
        </div>
        )}
        {filter(options).map((option, index) => {
          return (
            <div
              onClick={() => selectOption(option)}
              className={`option ${option.name === value ? "selected" : ""
                }`}
              key={`${id}-${option.id}`}
            >
              {option.name}
            </div>
          );
        })}
        {filter(options).length === 0 && <div
              className="no-option"
            >
              no matching accounts
            </div>}
      </div>
    </div>
  );
};

export default SearchableDropdown;
