import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../components/Layout";
import PageContent from "../../components/Activity/PageContent";
import Loader from "../../components/Loader";
import ActivityService from "../../services/ActivityService";
import {
  hideAlert,
} from "../../store/reducers/alert";
import "../../styles/pages/activity.scss";

const activityService = new ActivityService();

const Activity = () => {
  const [activities, setActivities] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const user = useSelector(
    (state) => state?.app?.profile
  );

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const _activity = await activityService.getActivities()
        setActivities(_activity);
      } catch (e) {
      } finally {
        setLoading(false);
      }
    }
    fetchData();
    return () => dispatch(hideAlert());
  }, [user?._id]);

  return !isLoading ? (
    <Layout>
      <div className="activity">
        <h2 className="justify-content-center mx-auto mw-1440">Activity</h2>
        <PageContent activities={activities}/>
      </div>
    </Layout>
  ) : (
    <Loader />
  );
};

export default Activity;
