/* eslint-disable no-console, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events, jsx-a11y/label-has-associated-control*/
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { DateTime } from "luxon";
import moment from "moment";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import clsx from "clsx";
import { isMobile } from "../../../utils";
import { Table, Thead, Tbody, Tr, Th, Td } from "../../../components/Table";
import SearchableDropdown from "../../../components/SearchableDropdown";
import DateRangePicker from "../../../components/DateRangePicker";
import PreviousIcon from "../../../components/Icons/Previous";
import NextIcon from "../../../components/Icons/Next";
import {
  getAddress,
  getName,
  numberWithCommas,
} from "../../../utils";
import "../../../styles/components/Activity/PageContent/style.scss";

const PAGE_COUNT = 25;
const PortfolioPageContent = React.memo(({ activities }) => {
  const [filteredActivity, setfilteredActivity] = useState([]);
  const [value, setValue] = useState("");
  const [page, setPage] = useState(0);
  const [pageActivity, setPageActivity] = useState([])
  const [dates, setDates] = useState({
    startDate: moment(new Date('8/12/2023')),
    endDate: moment(new Date())
  });
  const [dateList, setDateList] = useState([])

  const parentAccounts = useSelector(
    (state) => state?.accounts?.parentAccounts
  );

  const ria = parentAccounts?.[0]?.type === 'ria'

  useEffect(() => {
    debounceFn()

  }, [activities, value])

  const accountOptions = useMemo(() => {
    return parentAccounts.map(a => {
      return {
        id: a._id,
        name: getName(a)
      }
    })
  }, [parentAccounts]);

  const handleApply = (option, index) => {
    setDates(option);
    setPage(index)
  }

  const filterActivity = useCallback(() => {
    const _sortedActivity = activities.filter(activity => {
      if (value === '') return true;
      if (value === 'ALL') return parentAccounts;
      let account = parentAccounts.filter(account => account._id === activity.accountId)?.[0]
      // if (ria && (activity.type === 'BUY' || activity.type === 'CANCELLED')) {
      //   account = parentAccounts[0];
      // }
      if (!account) return false
      return getName(account).toLowerCase().includes(value.toLowerCase())
    })
      .sort((a, b) => moment(b.created).valueOf() - moment(a.created).valueOf());

    let minDate = moment(new Date());
    let maxDate = moment(new Date('01/01/2023'));
    let _dateList = []
    _sortedActivity.forEach((activity, index) => {
      if (index % PAGE_COUNT === 0 || index === _sortedActivity.length - 1) {

        if (index !== 0 || _sortedActivity.length === 1) {

          if(index % PAGE_COUNT === 0) {
            _dateList.push({
              startDate: minDate,
              endDate: maxDate,
              id: (Math.random() + 1).toString(36).substring(7)
            })

            minDate = moment(new Date());
            maxDate = moment(new Date('01/01/2023'));
          }

          if (index === _sortedActivity.length - 1) {
            const createdDate = moment(activity.created).valueOf();
            if (createdDate > maxDate.valueOf()) {
              maxDate = moment(activity.created);
            }
            if (createdDate < minDate.valueOf()) {
              minDate = moment(activity.created);
            }
            _dateList.push({
              startDate: minDate,
              endDate: maxDate,
              id: (Math.random() + 1).toString(36).substring(7)
            })

            minDate = moment(new Date());
            maxDate = moment(new Date('01/01/2023'));
          }
        }

      }

      const createdDate = moment(activity.created).valueOf();
      if (createdDate > maxDate.valueOf()) {
        maxDate = moment(activity.created);
      }
      if (createdDate < minDate.valueOf()) {
        minDate = moment(activity.created);
      }
    })

    setDates(_dateList[0]);
    setDateList(_dateList);
    setfilteredActivity(_sortedActivity);
    setPage(0)
  }, [activities, parentAccounts, value, dates]);

  useEffect(() => {
    setPageActivity(filteredActivity.slice(page * PAGE_COUNT, (page + 1) * PAGE_COUNT))
    setDates(dateList[page]);
  }, [page, filteredActivity])

  const debounceFn = debounce(filterActivity, 300);

  const getNameFromActivity = (activity) => {
    let account = parentAccounts.filter(account => account._id === activity.accountId)?.[0]
    // if (ria && (activity.type === 'BUY' || activity.type === 'CANCELLED')) {
    //   account = parentAccounts[0];
    // }
    if (!account) return ''
    return getName(account)
  }

  const getAddressFromActivity = (activity) => {
    let account = parentAccounts.filter(account => account._id === activity.accountId)?.[0]
    // if (ria && (activity.type === 'BUY' || activity.type === 'CANCELLED')) {
    //   account = parentAccounts[0];
    // }
    if (!account) return ''
    return getAddress(account)
  }

  return (
    <Row className="justify-content-center mx-auto mw-1440 activity_content">
      <Col md={6} >
        <SearchableDropdown
          options={accountOptions}
          label="Investor Account"
          placeholder="All"
          id="id"
          value={value}
          handleChange={(val) => setValue(val)}
          className="search"
        />
      </Col>
      <Col md={6} >
        <div className="date-container">
          <DateRangePicker
            options={dateList}
            label="Date Range"
            placeholder="All"
            id="id"
            value={dates}
            handleChange={(val) => setDates(val)}
            handleApply={handleApply}
            className="date"
          />
          <div className="page-controller">
            <div className="d-flex align-items-center clickable" style={{ marginLeft: "10px" }}>
              <PreviousIcon disabled={page === 0} onClick={() => {
                setPage(prev => {
                  if (prev > 0)
                    return prev - 1;
                  return prev;
                })
              }} />
            </div>
            <div className="d-flex align-items-center clickable" style={{ marginLeft: "10px" }}>
              <NextIcon disabled={page >= filteredActivity?.length / PAGE_COUNT - 1} onClick={() => {
                setPage(prev => {
                  if (filteredActivity?.length / PAGE_COUNT - 1 > prev)
                    return prev + 1;
                  return prev;
                })
              }} />
            </div>
          </div>

        </div>

      </Col>
      <Col md={12} className={clsx("second-table", {
        "mt-0": pageActivity?.length === 0
      })}>
        <Table>
          <Thead>
            <Tr>
              <Th style={{ width: '35%' }}>
                Investor
              </Th>
              <Th style={{ width: '41%' }}>Activity</Th>
              <Th style={{ width: '24%' }}>Date / Time</Th>
            </Tr>
          </Thead>
          <Tbody>
            {pageActivity?.map((activity) => {
              const created = new Date(activity.created);
              return (
                <Tr key={activity._id}>
                  <Td className="portfolio-div">
                    <div className="account-field">
                      <label className="name-label">
                        {getNameFromActivity(activity)}
                      </label>
                      <label>{getAddressFromActivity(activity)}</label>
                    </div>
                  </Td>
                  <Td>
                    <div className="assets-div">
                      <div>
                        <b>{activity.type}:</b> {isMobile() && <br />}{activity.opal_name} Opal{activity.opal_suffix}
                      </div>
                      <div>
                        ${numberWithCommas(activity.units * activity.unit_price, false)} ({numberWithCommas(activity.units, false)} unit{activity.units === 1 ? '' : 's'} @ ${numberWithCommas(activity.unit_price, false)}/unit)
                      </div>
                    </div>

                  </Td>
                  <Td>
                    <div className="date-div">
                      <div>
                        {DateTime.fromJSDate(created).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY).replaceAll(',', '')}

                      </div>
                      <div>
                        {DateTime.fromJSDate(created).toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)}
                      </div>
                    </div>

                  </Td>
                </Tr>
              );
            })}
          </Tbody>

        </Table>
        {
          filteredActivity.length === 0 && <div className="no-activity">
            No Activity has occurred.
          </div>
        }
      </Col>
    </Row>
  );
});

export default PortfolioPageContent;
