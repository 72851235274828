import React, { useEffect, useRef, useState } from "react";
import "../../styles/components/DateRangePicker/style.scss";

const DateRangePicker = ({
  options,
  label,
  id,
  value,
  placeholder,
  handleApply,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", toggle);
    return () => document.removeEventListener("click", toggle);
  }, []);

  const selectOption = (option, index) => {
    handleApply(option, index);
    setIsOpen((isOpen) => !isOpen);
  };

  const toggle = (e) => {
    setIsOpen(e && e.target === inputRef.current);
  }

  const getLabel = (option) => {
    if (!option) return ''
    let countOfSame = 0;
    let optionIndex = 0;
    if (option.endDate.format('M/DD/YYYY') === option.startDate.format('M/DD/YYYY')) {
      options.forEach(o => {
        if (o.endDate.format('M/DD/YYYY') === option.endDate.format('M/DD/YYYY') && o.startDate.format('M/DD/YYYY') === option.startDate.format('M/DD/YYYY')) {
          countOfSame ++;
          if (option.id === o.id) {
            optionIndex = countOfSame;
          }
        }
      });
    }

    if (countOfSame > 0) {
      return `${option.endDate.format('M/DD/YYYY')}${optionIndex === 1 ? '' : `(${optionIndex - 1})`}`  
    }
    return `${option.endDate.format('M/DD/YYYY')} - ${option.startDate.format('M/DD/YYYY')}`
  }
  return (
    <div className={`${className} date-dropdown`}>
      <div className="control">
        <div className="selected-value">
          <label>{label}</label>
          <input
            type="text"
            ref={inputRef}
            value={getLabel(value)}
            name="datesearchTerm"
            placeholder={placeholder}
            onClick={toggle}
            readOnly
            className={`${isOpen ? "open" : ""}`}
          />
        </div>
        <div className={`arrow ${isOpen ? "open" : ""}`} onClick={toggle}></div>
      </div>

      <div className={`options ${isOpen ? "open" : ""}`}>
        {options.map((option, index) => {
          return (
            <div
              onClick={() => selectOption(option, index)}
              className={`option ${option.id === value?.id ? "selected" : ""}`}
              key={`${id}-${option.id}`}
            >
              {getLabel(option)}
            </div>
          );
        })}
        {options.length === 0 && <div
          className="no-option"
        >
          no matching dates
        </div>}
      </div>
    </div>
  );
};

export default DateRangePicker;
